<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card class=" p-5">
          <h3 class="font-weight-bold mb-4" style='font-size: 30px'>Nueva Factura</h3>
          <div class="d-flex flex-row justify-content-between align-items-center" style="cursor: pointer;"
            @click="toggleCollapse(index)">
            <article>
              <h3 class="font-weight-bold mb-2" style='font-size: 20px'>Empresa</h3>
              <p class=" mb-2" style='font-size: 15px'>Cambie la dirección, el logotipo y otra
                información para su empresa.</p>
            </article>
            <h2 v-show="isCollapsed[index]"><i class="fa-solid fa-caret-down"></i></h2>
            <h2 v-show="!isCollapsed[index]"><i class="fa-solid fa-caret-up"></i></h2>
          </div>
          <hr style='height: 1px; background: var(--dark);' class='w-100 mb-3' />
          <transition name="fade">
            <div v-show="!isCollapsed[index]" class="pt-3 pb-5 row">
              <div class="col-12 col-md-4 py-2">
                <article class='mb-4'>
                  <label>Titulo</label>
                  <b-form-input id="input-live" v-model="valueNombre"
                    aria-describedby="input-live-help input-live-feedback" placeholder="Ingrese Titulo"
                    trim></b-form-input>
                </article>
                <article>
                  <label>Subtitulo</label>
                  <b-form-input id="input-live" v-model="valueNombre"
                    aria-describedby="input-live-help input-live-feedback" placeholder="Ingrese Subtitulo"
                    trim></b-form-input>
                </article>
              </div>
              <div class="col-12 col-md-4 py-2">
                <label>Logo</label>
                <div class="d-flex w-100 h-100">
                  <label for="dropzone-file"
                    class="custom-file-drop-area p-3 d-flex flex-column align-items-center justify-content-center cursor-pointer"
                    style="width: 12rem; height: 8rem">
                    <p class="fs-5 py-3 mb-0">Arrastre los archivos aquí pasa subirlos</p>
                    <input id="dropzone-file" type="file" hidden />
                  </label>
                </div>
              </div>
              <div class="col-12 col-md-4 py-2">
                <div class='d-flex align-items-center justify-content-between'>
                  <article class='d-flex flex-column justify-content-center' style='gap: .7rem;'>
                    <label class='mb-0'>Mi compañía</label>
                    <p class='mb-0'>my@company.com</p>
                  </article>
                  <vs-button icon><ion-icon name="pencil-sharp"></ion-icon></vs-button>
                </div>
              </div>
            </div>
          </transition>
          <div class='pt-3'>
            <h3 class="font-weight-bold mb-2" style='font-size: 20px'>Facturación</h3>
            <p class="mb-2" style='font-size: 13px'>Los datos de facturación aparecen en su factura. La fecha de la
              factura se utiliza en el panel de control e informes. Seleccione la fecha que usted espera que se le pague
              como fecha de vencimiento.</p>
          </div>
          <hr style='height: 1px; background: var(--dark);' class='w-100 mt-4' />
          <div class='row pt-3'>
            <div class="col-12 col-md-6 py-2">
              <label>selecione el cliente*</label><br />
              <b-form-select v-model="seleccionada" :options="listaClientes"></b-form-select>
              <div class='pt-2'>
                <label>Factura a</label>
                <h3>Abraham Hickle</h3>
                <p class="mb-1">46800 Kaley Crest Apt. 438 Port Daniella</p>
                <p class="mb-1">Mayotte</p>
                <p class="mb-1">CIF/NIF: 3166</p>
                <p class="mb-1">(831) 561-3786 - morris04@hotmail.com</p>
                <b-button variant="link">Editar Cliente</b-button>
              </div>
            </div>
            <div class="col-12 col-md-3 py-2">
              <article class='mb-4'>
                <label>Fecha de Factura *</label><br />
                <input placeholder="" type="date" class="form-control " />
              </article>
              <article>
                <label>Fecha de vencimiento *</label><br />
                <input placeholder="" type="date" class="form-control " />
              </article>
            </div>
            <div class="col-12 col-md-3 py-2">
              <article class='mb-4'>
                <label>Número de Factura *</label><br />
                <b-form-input id="input-live" v-model="valueNombre"
                  aria-describedby="input-live-help input-live-feedback" placeholder="Ingrese Nombre"
                  trim></b-form-input>
              </article>
              <article>
                <label>Nº Pedido</label><br />
                <b-form-input id="input-live" v-model="valueNombre"
                  aria-describedby="input-live-help input-live-feedback" placeholder="Ingrese Nombre"
                  trim></b-form-input>
              </article>
            </div>
          </div>
          <vs-table>
            <template #thead>
              <vs-tr>
                <vs-th>
                  Articulo
                </vs-th>
                <vs-th style='width: 280px !important;'>
                  Descripción
                </vs-th>
                <vs-th style='width: 180px !important;'>
                  cantidad
                </vs-th>
                <vs-th style='width: 180px !important;'>
                  Precio
                </vs-th>
                <vs-th style='width: 150px !important;'>
                  Impuesto %
                </vs-th>
                <vs-th style='width: 150px !important;'>
                  Valor
                </vs-th>
                <vs-th>
                  Opciones
                </vs-th>
              </vs-tr>
            </template>
            <template #tbody>
              <vs-tr v-for="(articulo, index) in articulos" :key="index">
                <vs-td>{{ articulo.nombre }}</vs-td>
                <vs-td>
                  <b-form-textarea rows="1" class='p-3' v-model="articulo.descripcion"
                    placeholder="Descripción"></b-form-textarea>
                </vs-td>
                <vs-td>
                  <b-form-input type="number" v-model="articulo.cantidad" min="1"></b-form-input>
                </vs-td>
                <vs-td>
                  <b-form-input type="number" v-model="articulo.precio" min="0" step="1"></b-form-input>
                </vs-td>
                <vs-td>
                  <b-form-input type="number" v-model="articulo.impuesto" min="0" max='100' step="1"></b-form-input>
                </vs-td>
                <vs-td>$ {{ calcularValor(articulo) }}</vs-td>
                <vs-td>
                  <vs-button class='bg-danger' @click="eliminarArticulo(index)">
                    <ion-icon name="trash-outline"></ion-icon>
                  </vs-button>
                </vs-td>
              </vs-tr>
            </template>
            <template #notFound>
              <vs-tr class='d-flex justify-content-center'>
                No hay Artículos
              </vs-tr>
            </template>
            <template #footer>
              <div v-if="!showSelect2" class='d-flex justify-content-center'>
                <vs-button dark shadow @click.prevent="toggleSelect2">Agregar Artículo +</vs-button>
              </div>
              <div v-if="showSelect2" class="d-flex justify-content-center mt-3">
                <b-form-select v-model="selectedItem" :options="options" @change="agregarArticulo"></b-form-select>
              </div>
            </template>
          </vs-table>
          <hr style='height: 1px; background: var(--dark);' class='w-100 mb-3' />
          <div class='d-flex flex-column justify-content-end align-items-end'>
            <article class='d-flex align-items-center mb-3' style='width: fit-content;gap: 30px;'>
              <h3>subTotal</h3>
              <p class='mb-0' style='width: 100px; font-size: 20px; text-align: end'>$ {{ calcularSubtotal() }}</p>
            </article>
            <article class='d-flex align-items-center mb-3' style='width: fit-content;gap: 30px;'>
              <h3 class='d-flex align-items-center flex-wrap' style="gap: 15px">Descuento <b-form-input
                  style='width: 63px;' v-model='descuento' type="number" min="0" max='100' step="1"></b-form-input> %
              </h3>
              <p class='mb-0' style='width: 100px; font-size: 20px; text-align: end'>$ {{ calcularSubtotal() *
                (descuento / 100)
                }}</p>
            </article>
            <article class='d-flex align-items-center' style='width: fit-content;gap: 30px;'>
              <h3>Total</h3>
              <p class='mb-0' style='width: 100px; font-size: 20px; text-align: end'>$ {{ descuento === 0 ?
                calcularSubtotal() :
                calcularSubtotal() - (calcularSubtotal() * (descuento / 100)) }}</p>
            </article>
          </div>
          <hr style='height: 1px; background: var(--dark);' class='w-100 mb-3' />
          <div class="d-flex flex-row justify-content-between align-items-center" style="cursor: pointer;"
            @click="toggleCollapse2(index)">
            <article>
              <h3 class="font-weight-bold mb-2" style='font-size: 20px'>Avanzado</h3>
              <p class=" mb-2" style='font-size: 13px'>Selecciona la categoría, añade o edita el pie de página y añade
                adjuntos a
                tu factura.</p>
            </article>
            <h2 v-show="isCollapsed2[index]"><i class="fa-solid fa-caret-down"></i></h2>
            <h2 v-show="!isCollapsed2[index]"><i class="fa-solid fa-caret-up"></i></h2>
          </div>
          <hr style='height: 1px; background: var(--dark);' class='w-100 mb-3' />
          <transition name="fade">
            <div v-show="!isCollapsed2[index]" class="pt-3 pb-5 row">
              <div class="col-12 col-md-6 py-2">
                <label>Pie de página</label>
                <b-form-textarea rows="6" class='p-3' placeholder="Ingrese pie de página"></b-form-textarea>
              </div>
              <div class="col-12 col-md-6 py-2">
                <article class='mb-3'>
                  <label>Categoria *</label>
                  <b-form-select :options="options"></b-form-select>
                </article>
                <article>
                  <label>Adjunto</label>
                  <div class="d-flex align-items-center justify-content-center w-100">
                    <label for="dropzone-file"
                      class="custom-file-drop-area d-flex flex-column align-items-center justify-content-center w-100 cursor-pointer">
                      <p class="fs-5 py-3 mb-0">Arrastre los archivos aquí pasa subirlos</p>
                      <input id="dropzone-file" type="file" hidden />
                    </label>
                  </div>
                </article>
              </div>
            </div>
          </transition>
          <div class='d-flex pt-4 justify-content-end'>
            <vs-button size="large" dark shadow @click.prevent="$router.push('Facturas')">Cancelar</vs-button>
            <vs-button size="large">Guardar</vs-button>
            <vs-button warn @click="active = !active">
              Ver Factura
            </vs-button>
            <vs-dialog class='bg-body overflow-hidden p-5' v-model="active">
              <template #header>
                <h4 class="not-margin font-weight-bold text-uppercase">
                  Factura electronica de venta fe34434
                </h4>
              </template>
              <div class="con-form d-flex flex-column p-3" style='gap: .5rem;'>
                <div class='row p-2 infocolor' style='background-color: var(--gray-300);'>
                  <article class='col-12 col-md-6 p-0'>
                    <label class='text-uppercase font-weight-bold m-0'>Latinoanmerica hosting sas</label>
                    <div v-for='(info, index) in headerInfo' :key='index' class='d-flex align-items-center'
                      style='gap: .5rem;'>
                      <label class='font-weight-bold m-0'>{{ info.title }}:</label>
                      <p class='m-0'>{{ info.value }}</p>
                    </div>
                  </article>
                  <article class='col-12 col-md-6 d-flex justify-content-end align-items-center mt-3 mt-md-0 p-0'>
                    <span style='width: 12rem; height: 12rem;'
                      class='border d-flex justify-content-center align-items-center m-auto m-md-0'>QR</span>
                  </article>
                </div>
                <div class='row p-2 infocolor' style='gap: .5rem; background-color: var(--gray-300);'>
                  <label class='font-weight-bold m-0'>CUFE:</label>
                  <p class='m-0 p-0 text-justify'>95738475v745764765934659c4563746d573467564</p>
                </div>
                <div class='row p-2'>
                  <div v-for='(info, index) in datosInfo' :key='index'
                    class='d-flex col-12 col-md-6 align-items-center p-0' style='gap: .5rem;'>
                    <label class='font-weight-bold m-0'>{{ info.title }}:</label>
                    <p class='m-0'>{{ info.value }}</p>
                  </div>
                </div>
                <div class='row p-2 infocolor' style='background-color: var(--gray-300);'>
                  <div class='d-flex col-12 col-md-6 align-items-center p-0' style='gap: .5rem;'>
                    <label class='font-weight-bold m-0'>Adquiriente:</label>
                    <p class='m-0 '> NIT: 7945739456</p>
                  </div>
                  <div class='d-flex col-12 col-md-6 align-items-center p-0' style='gap: .5rem;'>
                    <label class='font-weight-bold m-0'>Razón Social:</label>
                    <p class='m-0 '>HELEXIUM SAS</p>
                  </div>
                </div>
                <div class='row p-2 separator'>
                  <div v-for='(info, index) in usuarioInfo' :key='index'
                    class='d-flex col-12 col-md-6 align-items-center p-0' style='gap: .5rem;'>
                    <label class='font-weight-bold m-0'>{{ info.title }}:</label>
                    <p class='m-0'>{{ info.value }}</p>
                  </div>
                </div>
                <div class='row p-2 infocolor' style='background-color: var(--gray-300);'>
                  <label class='font-weight-bold m-0'>Detalles de Productos</label>
                </div>
                <div class='row overflow-auto separator pb-3' style='max-width: 800px;'>
                  <table class='tablefactura' border="1" cellpadding="5" cellspacing="0"
                    style="border-collapse: collapse; width: 100%;font-size: .6rem;">
                    <thead>
                      <tr>
                        <th colspan="6"></th>
                        <th colspan="2">Cargos o Descuentos</th>
                        <th colspan="3">Impuestos</th>
                        <th></th>
                      </tr>
                      <tr>
                        <th>Nro</th>
                        <th>Código</th>
                        <th>Descripción</th>
                        <th>U/M</th>
                        <th>Cantidad</th>
                        <th>Precio Unitario</th>
                        <th>Descuento</th>
                        <th>Recargo</th>
                        <th>IVA</th>
                        <th>%IVA</th>
                        <th>INC</th>
                        <th>Total item</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>DOMINIO</td>
                        <td>DOMINIO Renovar Dominio - helexium.com - 1 Año(s) (21/07/2024 - 20/07/2025) + Gestionar DNS
                          +
                          Redirección de Email</td>
                        <td>UNIDA</td>
                        <td>1,00</td>
                        <td>$44.117,65</td>
                        <td>$0,00</td>
                        <td>$0,00</td>
                        <td>$8.382,35</td>
                        <td>19%</td>
                        <td>$0,00</td>
                        <td>$44.117,65</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="2">Total items</td>
                        <td colspan="10" style='text-align: end;'>1</td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div class='row p-2 infocolor' style='background-color: var(--gray-300);'>
                  <label class='font-weight-bold m-0'>Documentos referenciados</label>
                </div>
                <div class='row overflow-auto separator pb-3' style='max-width: 800px;'>
                  <table class='tablefactura' border="1" cellpadding="5" cellspacing="0"
                    style="border-collapse: collapse; width: 100%;font-size: .6rem;">
                    <thead>
                      <tr>
                        <th colspan='6'>Tipo de Documento Referencia</th>
                        <th colspan='4'>Número Referencia</th>
                        <th>Fecha Referencia</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colspan='6'>Orden de compra </td>
                        <td colspan='4'>301932</td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class='row p-2 infocolor' style='background-color: var(--gray-300);'>
                  <p class='font-weight-bold m-0'>Totales</p>
                </div>
                <div class="row">
                  <article class='d-flex col-12 col-md-7 align-items-center p-0 mb-3' style='gap: .5rem;'>
                    <label class='font-weight-bold m-0'>Nota:</label>
                    <p class='m-0 '> Las retenciones son a nivel informativo no afecta el total a pagar.</p>
                  </article>
                  <article class='col-12 col-md-5 p-0' style='gap: .5rem;'>
                    <div v-for='(info, index) in totalInfo' :class='info.border && "separator2"' :key='index'
                      class='d-flex justify-content-between align-items-center p-0' style='gap: .5rem;'>
                      <p class='m-0'>{{ info.title }}:</p>
                      <p :class='info.bold && "h6 font-weight-bold"' class='m-0'>{{ info.value }}</p>
                    </div>
                  </article>
                </div>
              </div>

              <template #footer>
                <div class='row mt-5'>
                  <p class='font-weight-bold text-center'>Numero de Autorización: 18764067673092 Rango Autorizado Desde:
                    10001 Rango Autorizado Hasta: 50000, Prefijo: FE, Fecha resolución: 2024-03-20, Vigente hasta:
                    2025-03-20
                    Proveedor Tecnológico: Nodexum S.A.S | NIT: 901285179-0 | Software: e-Misión</p>
                </div>
              </template>
            </vs-dialog>
          </div>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  export default {
    data() {
      return {
        active: false,
        isCollapsed: {},
        isCollapsed2: {},
        showSelect: false,
        isDragging: false,
        showSelect2: false,
        descuento: 0,
        selectedItem: null,
        articulos: [],
        headerInfo: [
          { title: "NIT", value: "9058393484-1" },
          { title: "Nombre comercial", value: "Lorem ipsum" },
          { title: "Tipo de Contribuyente", value: "Persona Juridica" },
          { title: "Direccion", value: "CIR 4 70 93 OF 302" },
          { title: "Régimen Contable", value: "Impuestos sobre las ventas - IVA" },
          { title: "Pais", value: "Colombia" },
          { title: "Municipio", value: "Medellín (Antioquia)" },
          { title: "Correo", value: "ventas@latinoanmericahosting.com.co" },
          { title: "Tipo Responsabilidad", value: "R-99-PN (No aplica - Otros)" },
          { title: "Teléfono", value: "3105363434" }
        ],
        datosInfo: [
          { title: "Fecha de Emisión", value: "2021-10-10" },
          { title: "Fecha de Vencimiento", value: "2021-10-10" },
          { title: "Tipo de Operación", value: "Estandar" },
          { title: "Prefijo", value: "FE" },
          { title: "Tipo de Negociación", value: "Contado" },
          { title: "Medio de Pago", value: "Transferencia Débito Bancaria" },
          { title: "Fecha de validación", value: "2024-07-22 17:38:20" },
          { title: "Periodo", value: "-" }
        ],
        usuarioInfo: [
          { title: "Nombre Comercial", value: "" },
          { title: "Dirección", value: "Carrera 7 calle 102" },
          { title: "Número Documento", value: "1342343534" },
          { title: "Pais", value: "Colombia" },
          { title: "Departamento", value: "Bogotá" },
          { title: "Municipio", value: "Bogotá D.C" },
          { title: "Tipo de Contribuyente", value: "Persona Jurídica" },
          { title: "Correo", value: "casargiraldopersonal@gmail.com" },
          { title: "Régimen Contable", value: "Inpuesto sobre las ventas - IVA" },
          { title: "Teléfono", value: "0" },
          { title: "Tipo de responsabilidad", value: "R-99-PN (No aplica - Otros)" }
        ],
        totalInfo: [
          { title: 'Moneda', value: 'COP', 'border': false },
          { title: 'Tasa de Cambio', value: '1', 'border': true },
          { title: 'Subtotal Precio Unitario (=)', value: '44.117,65', 'border': false },
          { title: 'Descuentos Detalle (-)', value: '0,00', 'border': false },
          { title: 'Recargos Detalle (+)', value: '44.117,65', 'border': true },
          { title: 'Subtotal Base Gravable (=)', value: '8.382,35', 'border': true },
          { title: 'Total Impuesto IVA (=)', value: '8.382,35', 'border': false },
          { title: 'Total Impuestos (+)', value: '8.382,35', 'border': false },
          { title: 'Total Mas Impuesto (=)', value: '52.500,00', 'border': true },
          { title: 'Descuento Global (-)', value: '0,00', 'border': false },
          { title: 'Recargo Global (+)', value: '0,00', 'border': true },
          { title: 'Total de la operación (=)', value: '52.500,00', 'border': false, 'bold': true }
        ],
        options: [
          { value: null, text: 'Seleccione un artículo' },
          { value: { nombre: 'Artículo 1', descripcion: 'Descripción 1', cantidad: 1, precio: 100, impuesto: 10 }, text: 'Artículo 1' },
          { value: { nombre: 'Artículo 2', descripcion: 'Descripción 2', cantidad: 2, precio: 200, impuesto: 20 }, text: 'Artículo 2' },
          { value: { nombre: 'Artículo 3', descripcion: 'Descripción 3', cantidad: 3, precio: 300, impuesto: 30 }, text: 'Artículo 3' }
        ]
      }
    },
    methods: {
      toggleCollapse(colapsar) {
        this.$set(this.isCollapsed, colapsar, !this.isCollapsed[colapsar]);
      },
      toggleCollapse2(colapsar) {
        this.$set(this.isCollapsed2, colapsar, !this.isCollapsed2[colapsar]);
      },
      toggleSelect() {
        this.showSelect = !this.showSelect;
      },
      toggleSelect2() {
        this.showSelect2 = !this.showSelect2;
      },
      agregarArticulo() {
        if (this.selectedItem) {
          this.articulos.push(this.selectedItem);
          this.showSelect2 = false;
          this.selectedItem = null;
        }
      },
      eliminarArticulo(index) {
        this.articulos.splice(index, 1);
      },
      calcularValor(articulo) {
        return articulo.impuesto === 0 ? (articulo.precio * articulo.cantidad) : (((articulo.precio * articulo.cantidad) * articulo.impuesto) / 100) + (articulo.precio * articulo.cantidad);
      },
      calcularSubtotal() {
        return this.articulos.reduce((subtotal, articulo) => {
          return subtotal + parseFloat(this.calcularValor(articulo));
        }, 0);
      }
    }
  }
</script>

<style scoped>
  .custom-file-drop-area {
    border: 2px dashed #cfcfcf;
    border-radius: 8px;
    text-align: center;
    color: #b0b0b0;
    background-color: transparent;
  }

  p {
    word-break: break-all;
  }

  .vs-table__thead .vs-table__th {
    background: var(--body-bg) !important;
  }

  .con-form {
    padding: 0;
  }

  .dark .tablefactura th,
  .dark .tablefactura td,
  .dark .tablefactura tr {
    border: 1.5px solid white;
    color: white;
  }

  .tablefactura th,
  .tablefactura td,
  .tablefactura tr {
    border: 1.5px solid black;
    color: black;
  }

  .vs-table__footer {
    background: var(--body-bg) !important;
  }

  .custom-file-drop-area:hover {
    border-color: #999;
    color: #777;
  }

  .vs-table {
    min-height: 3rem !important;
  }

  p .title {
    font-weight: bold;
    font-size: .8rem;
  }

  .dark .infocolor {
    background: #ffffff17 !important;
  }
</style>
<style>
  .vs-table__footer {
    background: var(--body-bg) !important;
  }

  .vs-dialog {
    background: var(--body-bg) !important;
    padding: 1.2rem;
    height: 85vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .dark .vs-dialog__header,
  .dark .separator {
    border-bottom: 1px solid white;
  }

  .vs-dialog__header,
  .separator {
    border-bottom: 1px solid black;
  }

  .dark .separator2 {
    border-bottom: 2px solid white;
  }

  .separator2 {
    border-bottom: 1.5px solid black;
  }

  .vs-dialog__header {
    margin: 0 1rem;
  }

</style>